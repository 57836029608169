// $dark-background: #1a2235;
$dark-background: #181924;


$font-family: 'Hanken Grotesk', sans-serif !important;
$textfield-text-color: #b3b6bf !important; 
// $textfield-focus-border-color: #74bae2 !important;
$textfield-focus-border-color: #857dcc !important;
$login-icon-color: #b3b6bf !important; 
$error-color: #e55353 !important;
$error-subtle-color: #f9d4d4 !important;

.login-page {
    
    background-color: $dark-background;

    min-height: 100vh;
    height: auto;

    //center the login container
    display: flex;
    justify-content: center;
    align-items: center;



    .login-logo-container {

        // background-color: red;

        height: 468px;
        width: 560px;
        border-radius: 12px;

        padding: 40px;

        //align items in center
        display: flex;
        align-items: center;
        justify-content: center; 


        .login-logo {
            height: 100px;
            width: auto;
        }
    }

    .login-logo-shrinked-container {
        display: none;
    }
    
    .divider {
        width: 1px;
        height: 300px; /* Adjust height to match your layout */
        background-color: #cccccc8c; /* Light gray color */
        margin: 0 20px 0px 66px; /* Spacing between the logo and form */
    }

    .login-main-container {
    //    background-color: red; 

        height: 468px;
        width: 560px;
        border-radius: 12px;

        padding: 40px;
        
        //align items in center
        display: flex;
        align-items: center;
        justify-content: center; 

        .login-form-wrapper {

            display: flex;
            flex-direction: column;
            gap: 12px;

            .login-title-wrapper {

                display: flex;
                flex-direction: column;

                .login-title {
                    font-family: $font-family;
                    color: #fefefe;
                    font-weight: 600;
                    font-size: 3rem;

                    //change default browser margin for text
                    margin-bottom: 4px;
                }
        
        
                .login-subheader {
    
                    color: #858e9e;
                    font-family: $font-family;
                    font-size: 1.1rem;
                    font-weight: 100 !important;
    
                    //change default browser margin for text
                    margin-bottom: 0;
                }
            }


            .login-form-container {

                display: flex;
                flex-direction: column;
                gap: 10px;

                margin-top: 6px;
                
                width: 316px;

                position: relative;

                .login-input-group {

                    border-color: #181924 !important;

                    
                    .input-icon-container, .login-input-field {
                        // border-color: #232a35 !important;
                        // border-color: #2c2d38  !important;
                        border-color: #3a3b45   !important;

                        // background-color: #2d3748 !important;
                        background-color: #24252f !important;
                        

                        .login-input-icon {
                            color: $login-icon-color;
                        }
                    }

                    .login-input-field-icon {
                        color: $textfield-text-color;
                    }

                    .login-input-field {
                        font-family: $font-family;
                        font-size: 1rem !important;
                        color: $textfield-text-color;
                    }

                    &:focus-within {
                        border-color: $textfield-focus-border-color;

                        .login-input-field {
                            border-color: $textfield-focus-border-color;
                            box-shadow: none !important;
                        }

                        .show-password-icon-container {
                            border-color: $textfield-focus-border-color;
                        }
                    }

                    .password-input-field {
                        border-right: none !important;
                    }

                    .password-input-field:focus {
                        border-right: none !important;
                    }
    
                    .show-password-icon-container {
                        border-left: none !important;

                        .show-password-icon:hover {
                           cursor: pointer; 
                        }
                    }



                }
                
                .login-error-toast {

                    background-color: $error-subtle-color;
                    
                    padding: 16px;

                    border: 1px solid;
                    border-color: $error-color;
                    border-radius: 8px !important;

                    position: absolute;
                    top: calc(100% + 10px);

                    width: 100%;

                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }

                .login-error-toast p {
                    color: $error-color;

                    font-family: $font-family;
                    font-size: 1rem !important;

                    margin: 0;
                    padding: 0;
                }

                .login-error-toast-icon {
                    color: $error-color;
                }

                .login-error-toast-icon:hover {
                    cursor: pointer;
                }

            }

            .login-button {
                // background-color: #0091d6 !important;
                border-radius: 8px !important;
                border: 2px !important;
                // border-color: #015f99 !important;
                font-family: $font-family;

                margin-top: 12px;

                // color: #fefefe;
            }

            .login-button:hover {
                // background-color: #259ed6 !important;
            }
        }

    }

}

@media (max-width: 1310px) {
    .login-page {

        display: flex;
        flex-direction: column;
        align-items: center;
        
        min-height: 100vh; /* Full height of the viewport */
        height: auto;
        

        position: relative;
        box-sizing: border-box;
        
        .login-logo-shrinked-container {
            
            display: block;
            
            margin-bottom: 490px;
            position: relative;

            // background-color: red;
            max-height: 170px;
            height: auto;
            width: auto; 

            .login-logo {
                
                height: 150px;
                width: auto;
            }
        }

        .login-logo-container {
            display: none;

            position: absolute;
            top: 40px;
            
            // background-color: red;
            max-height: 64px;
            height: auto;
            width: auto; 

            .login-logo {
                
                height: 50px;
                width: auto;
            }
        }

        .login-main-container {
            padding: 0;
            height: auto;
            width: auto;

            position: absolute;
            top: 50%;
            transform: translateY(-50%); /* Center the form vertically */


        }

        .divider {
            display: none;
        }
    }
}


@media (max-height: 670px) and (max-width: 1309px) {

    .login-page {
        padding-top: 40px;

        .login-logo-shrinked-container {
            .login-logo {
                
                height: 120px;
                width: auto;
            }
        }
    }

}