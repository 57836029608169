// Here you can add other styles

//Xrisimopeitai sto Widgets_Dashboard
.my-active {
  background-color: #83bf6e80; /* green trasparency last two digits from 00 to FF*/
  color: #83bf6e; /* green */
  text-align: center;
  font-weight: bold;
  padding: 3px 20px 5px 20px;
  display: inline;
  border-radius: 5px;
}

.my-rehab {
  background-color: #ffc55480; /* orange trasparency last two digits from 00 to FF*/
  color: #ffc554; /* orange */
  text-align: center;
  font-weight: bold;
  padding: 3px 20px 5px 20px;
  display: inline;
  border-radius: 5px;
}

.my-inactive {
  background-color: #ff6a5580; /* red trasparency last two digits from 00 to FF */
  color: #ff6a55; /* red */
  text-align: center;
  font-weight: bold;
  padding: 3px 20px 5px 20px;
  display: inline;
  border-radius: 5px;
}

//Xrisimopeitai sto Widgets_Dashboard
.my-vertical {
  display: flex;
  align-items: center; /* vertical alignment */
  justify-content: center; /* horizontal alignment */
}

//Fonto apo logo artemys gia na tairiazei stin ipoloipi stili
.sidebar-brand {
  background-color: rgba(#000015, 0);
}

.videoWrapper {
  position: relative;
  width: 100%;
  padding-top: 39.56%; // This reserves the space for the video maintaining the native aspect ratio
}

.videoWrapper video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
  overflow: hidden;
  pointer-events: none; // Remove if you want clickable controls
}
