$primary-color: #857dcc;

.fc {
  --fc-multimonth-title-color: #343a40;
  --fc-small-font-size: 0.85em;
  --fc-page-bg-color: #343a40;
  --fc-neutral-bg-color: #343a40;
  --fc-neutral-text-color: #808080;
  --fc-border-color: transparent;
  //var(--cui-border-color, #{$border-color});
  --fc-button-text-color: #fff;
  --fc-button-bg-color: #2c3e50;
  --fc-button-border-color: #2c3e50;
  --fc-button-hover-bg-color: #1e2b37;
  --fc-button-hover-border-color: #1a252f;
  --fc-button-active-text-color: #000;
  --fc-button-active-bg-color: #1a252f;
  --fc-button-active-border-color: #151e27;
  --fc-event-bg-color: #3788d8;
  --fc-event-border-color: #3788d8;
  --fc-event-text-color: transparent;
  --fc-event-selected-overlay-color: rgba(0, 0, 0, 0.25);
  --fc-event-resizer-thickness: 8px;
  --fc-event-resizer-dot-total-width: 8px;
  --fc-event-resizer-dot-border-width: 1px;
  --fc-non-business-color: rgba(215, 215, 215, 0.3);
  --fc-bg-event-color: rgb(143, 223, 130);
  --fc-bg-event-opacity: 0.3;
  --fc-highlight-color: rgba(188, 232, 241, 0.3);
  --fc-today-bg-color: transparent; //rgba(255, 220, 40, 0.15); original
  --fc-now-indicator-color: var(--cui-danger);
}

.fc-day {
  background-color: #343a40;
  border: none;
  text-decoration: none;
  border-bottom: none;
  align-content: center;
}

.fc-multimonth-header {
  background-color: #343a40;
  border: none;
  border-bottom: none;
}

.fc-daygrid-day-number {
  text-decoration: none;
}

.fc-col-header-cell-cushion {
  text-decoration: none;
}

.fc .fc-col-header-cell :hover {
  color: $primary-color !important;
}

.fc .fc-daygrid-day-top {
  display: flex;
  flex-direction: column;
  align-items: center;
  :hover {
    color: $primary-color;
  }
}

.fc .fc-bg-event {
  border-radius: 10px;
}

.fc-h-event .fc-event-main {
  height: 4px;
}

a.fc-event,
a.fc-event:hover {
  opacity: 0.8;
}

.fc .fc-daygrid-event-harness {
  position: relative;
  max-width: 50%;
  min-width: 50%;
  float: left;
  margin-bottom: 2px;
}

.fc .fc-multimonth-compact {
  line-height: 0.9;
}